<template>
  <div    v-motion
          :initial="{
      opacity: 0,
      y: 100,
    }"
          :visibleOnce="{
      opacity: 1,
      y: 0,
        transition: {
      type: 'spring',
      stiffness: '100',
      delay: 300,
    },
    }">
    <div class="container">
      <div class="row  align-items-center">
        <div class="col-lg-4 d-flex d-lg-block col-sm-12 col-md-12 second-space justify-content-center">
          <ImageComponent :src=imgItem width="300px"/>
        </div>
        <div class="col-lg-6  col-sm-12 col-md-12 left-side">
          <h1 class="head-title">
            {{headTitle}}       </h1>
          <h3 class="font-20" >Car Wash At Home Services
          </h3>
          <p class="p-description fw-400 text-start" v-html="descriptionHtml"></p>

          <h1 class="head-title">
            Download App</h1>
<div> <image-component :src="android"/> <image-component :src="apple"/></div>
          <div class="col-12 ">
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import ImageComponent from "@/components/entity/ImageComponent.vue";
export default {
  props: {
    headTitle: {type:String,
    default:"About Us"
    },
  description:{type:String,
  default:" Roshosh wants to bring an end to the queue culture when <br>" +
      "            it comes to car wash. Hence our team has come up with an <br>" +
      "            app where you can choose from a variety of high-quality <br>" +
      "            washes and our car washers will be there to do it."
  },
    imgItem:{type: String,
      default:require('../../assets/roshosh/car & mobile.png')
  },},
  components: {

    ImageComponent},
  methods:{

  },
  data(){
    return{
      android: require('../../assets/roshosh/Google play.png'),
      apple: require('../../assets/roshosh/app store.png')
    }
  },
  computed:{
    descriptionHtml(){
      return this.description
    }
  }



}

</script>

<style scoped>
.font-20{
  font-weight: 500;
  color:var(--main-color);
}
.p-description{
  font-size: 18px;
}
.left-side{
  height: max-content;
  max-width:100%;

}
.head-title{
  color:var(--main-color);
  width: max-content;
  max-width: 100%;
  font-size:32px;
  font-weight: 700;
}
.second-title{
  font-weight: 700;

}
.title{
  font-size: 20px;
}
.p-font{
  font-size: 18px;
}
.highlighted{
  color:var(--primary-text);
}
.second-space{
  background-repeat: no-repeat;
  background-size: 100%;
}

</style>

