import { createRouter, createWebHistory } from 'vue-router'
import LandPage from './../components/LandPage.vue'

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            // You could also have named views at tho top
            component: LandPage,
            children: [
                {
                    path: '/',
                    component: LandPage,
                },

            ],
        },
    ],
})
