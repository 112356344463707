<template>
  <div    v-motion
          :initial="{
      opacity: 0,
      y: 100,
    }"
          :visibleOnce="{
      opacity: 1,
      y: 0,
        transition: {
      type: 'spring',
      stiffness: '100',
      delay: 300,
    },
    }">
    <section class="container mt-0">
      <div class="d-flex  ">
        <div class="col-md-12">
          <h3 class="white f-40 f-700 text-start mb-5 head-title ">
How To Use Roshosh ?
          </h3>
          <div class="cards-section align-items-center gx-5  d-lg-flex row justify-content-around">
            <div class="col-lg-2 col-md-3 col-sm-4 col-6 mb-3 " v-for="(item, index) in imgItems.slice(0, 5)" :key="index">
                <SmallCardComponent     :url="item.thumbnail" :height="item.height" :description="item.description" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SmallCardComponent from "@/components/CardComponent.vue";
export default {
  name: 'IndustrySection',
  props: {
    msg: String,
    Head: {
      type:String,
      default:""
    },
  },
  components: {
    SmallCardComponent,

  },
data(){
    return{
      imgItems: [
        {description:"download the <br>" +
              "Roshosh app",
          thumbnail: require('../../assets/roshosh/mobile_ icon.png'),
          height:"60px",
          title: ''
        },
        {description:"Select the desired <br>service and time ",
          thumbnail: require('../../assets/roshosh/clock icons.png'),
          height:"60px",

          title: ''
        },
        {
          description:"Get the premium <br>wash on selected date",
          thumbnail: require('../../assets/roshosh/calender.png'),
          height:"60px",

          title: ''
        },
      ],
    }
  }



}

</script>

<style scoped>
.row{
  margin-left:0 !important;}
.head-title{
  color:var(--main-color);
  font-weight: 700;
}
.cards-section{
  background:  linear-gradient(to bottom, #e7f8fa 80%, var(--light-color) 10%);
border-top: 20px solid var(--light-color);
}
.f-40{
  font-size: 40px;
}.f-25{
  font-size: 25px;
}
 .f-30{

  font-size: 30px;
 }
</style>
