<template>
  <div class="main-section"   v-motion
          :initial="{
      opacity: 0,
      y: 100,
    }"
          :visibleOnce="{
      opacity: 1,
      y: 0,
        transition: {
      type: 'spring',
      stiffness: '100',
      delay: 300,
    },
    }">
    <section class="container">
<div class="logo-space">

  <div class="logo-space col-6 col-lg-2 col-md-2 col-sm-4">
    <div class="d-flex justify-content-center">
      <ImageComponent width="150px" :src="logo"/>

    </div>
  </div>
</div>      <div class="row justify-content-center mx-auto ">
        <h3 class="head-title white f-30 f-700 text-center">
          On Demand Car Wash At Home         </h3>
        <div class="col-md-12">
          <h2 class=" font-30  text-center">
            Download the Roshosh app today to get your car the glow it deserves.<br>
            Call our car washers to your home to give your car a premium wash!
          </h2>
          <p class=" white font16 f-400 text-center"></p>
          <div class="align-items-center gx-5 d-none d-lg-flex row justify-content-around ">
            <div class="col-md-2 mb-4 cards-section" v-for="(item, index) in imgItems" :key="index">
              <ImageComponent  :src="item.thumbnail" :width="item.width" />
            </div>
          </div>
        </div>
        <div class="col-12 row justify-content-center ">

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ImageComponent from "@/components/entity/ImageComponent.vue";
export default {
  name: 'ThirteenSection',
  props: {
    msg: String,
    modalOpen:Function
  },
  methods:{
    modalOpenUpParent() {
      this.modalOpen(); // Call the parent function passed as a prop
    },
  },
  components: {
    ImageComponent,
  },
  data(){
    return{
      imgItems: [
        {title:"",
          country:"",
          width:"250px",
          thumbnail: require('../../assets/roshosh/mobile_ 2.png'),
          description: ''
        },
        {title:"",
          width:"250px",
          thumbnail: require('../../assets/roshosh/Mobile_ 1.png'),
          description: ''},
        {
          title:" ",
          width:"250px",
          thumbnail: require('../../assets/roshosh/mobile_3.png'),
          description: ''
        },

      ],
      logo : require('../../assets/roshosh/roshosh.png'),

    }
  }



}

</script>

<style scoped>
.font-30{
  font-size: 20px;
  color:var(--main-color);
}
.main-section{
  padding-top: 60px;
  background-image: url('/src/assets/roshosh/background_.jpg') ;
  background-size: 100% 75% ;
  background-repeat: no-repeat;

}
.head-title{
  margin-bottom: 30px;
  color: var(--light-color);
  width: max-content;
  max-width: 100%;
  font-size:32px;
  font-weight: 700;
}
</style>
