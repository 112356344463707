<template>
  <div>
    <section>
      <div>
        <ThirteenSection :modalOpen="modalOpen" />
        <FirstSection :modalOpen="modalOpen" />
        <IndustrySection :modalOpen="modalOpen" />
        <FirstSection :modalOpen="modalOpen" :imgItem="roshoshPhoto" headTitle="Why should you get Roshosh?" description="Convenience: Get your car washed anytime, anywhere!<br>
Save Time: Book your car wash appointment in seconds through the Roshosh app.<br>
Professional Detail: Highly trained technicians will wash and detail your car.<br>
Mobile App: The Roshosh app allows you to easily schedule appointments, and track their progress, all from your phone"/>



      </div>
    </section>
  </div>
</template>
<script>

import FirstSection from "@/components/sections/FirstSection.vue";
import ThirteenSection from "@/components/sections/ThirteenSection.vue";
import IndustrySection from "@/components/sections/IndustrySection.vue";
import {provide} from "vue";
export default {
  name: 'LandPage',
  props: {
    msg: String,
  },
  components: {

    IndustrySection,
    ThirteenSection,
    FirstSection,
  },

  data() {
    return {
      roshoshPhoto:require("/src/assets/roshosh/mobile_ 4.png"),
      header:"Success!",
      message:"We received your application and will contact you shortly.",
      buttonText:"confirm",
      isOpen:false,
      isShow:false,
      slides: ['Slide 1', 'Slide 2', 'Slide 3','Slide 4', 'Slide 25', 'Slide 6'] // Add your slide content here
    };
  }
,  setup() {
  const modalclose = () => {
    console.log("here")
    this.isShow=false

  };

  provide('handleEvent', modalclose);

  return {};
},
  methods: {
    modalOpen(){
      this.isOpen=true;

      },
    modalCloseUp(){
      console.log("here parents")
      this.isOpen=false;
      this.isShow=false;

    },    modalOpenUp(){
      console.log("here parents")
      this.isShow=true;

    },   closeForm(){
      console.log("here parents")
      this.isOpen=false;

    },

  }
}

</script>

<style>


.container{
  margin-top: 50px;
  margin-bottom: 100px;
}


.slideTrack {
  width: calc(150px* 20);
  display: flex;
  animation: scroll 20s linear infinite;
  justify-content: space-between;
}

.head-title {
  text-align: start;
  font-weight: 700;
}


.description{
  margin-top: 30px;
  text-align:start;
}
.standard-text-area-filed {
  width: 100%;
  height: 150px;
  background-color: transparent;
  border: 1px solid #e4e5e7;
  border-radius: 5px;
  color: light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4));

}








h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
