
<template>
<div class="">

</div>
</template>


<script>
export default {
  props: {
    scroll: { type: Function }
  },
  components: {},

  data() {
    return {

    };
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.detail{
  font-size: 16px;
}
.main-header{
  box-shadow: 1px -1px 24px 0px rgba(181, 181, 181, 0.75);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
 width: 100%;
}
.menu-item{
  font-size: 20px;
  font-weight: 700;
  display: flex;
  /* align-content: center; */
  justify-content: center;
  align-items: center;
}
.menu-item:hover{
  transition: 0.3s ease-in-out;
  transform: scale(1.05);
  cursor: pointer;

}
</style>
