
<template>
  <div class="">
    <div class="main-header d-flex justify-content-center align-items-center ml-0 mr-0 p-0">
      <div class="row justify-content-center w-75" >
        <div class="logo-space col-lg-8 col-md-12 col-sm-12 col-12">
          <div class="d-flex  justify-content-center flex-column">

            <ImageComponent width="250px" :src="logo"/>
            <div>
            </div>

          </div>
        </div>


        <div class="menu-section col-lg-4  col-md-12 col-sm-12 col-12 d-flex ml-0 mr-0  p-0">
          <div class="menu-item col">
            <div class="col">

              <p class="detail">    <ImageComponent  width="25px" />  +971(0)42233444</p>
              <p class="detail"> <ImageComponent  width="25px" /> info@technologies.ae</p>
              </div></div>

        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ImageComponent from "@/components/entity/ImageComponent.vue";
export default {
  props: {
    scroll: { type: Function }
  },
  components: {  ImageComponent},

  data() {
    return {
      logo:require("@/assets/roshosh/roshosh.png"),
      flag:require("@/assets/icon/flag/united-arab-emirates.png"),
      email:require("@/assets/icon/gmail.png"),
      location:require("@/assets/icon/map.png"),
    };
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.detail{
  font-size: 16px;
}
.main-header{
  background-color: var(--roshosh-color);
  color: var(--light-color);
  width: 100%;
  border-top: 3px solid;
  min-height: 200px;
}
.menu-item{
  font-size: 20px;
  font-weight: 700;
  display: flex;
  /* align-content: center; */
  justify-content: center;
  align-items: center;
}
.menu-item:hover{
  transition: 0.3s ease-in-out;
  transform: scale(1.05);
  cursor: pointer;

}
</style>
