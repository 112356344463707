<template>
  <div class=" card-content  p-1  direction-column justify-content-around pt-4">
    <ImageComponent class="icon-link" v-bind:src=menu   width="30px" alt=""/>

    <div class="col-lg-12 d-flex justify-content-center" >
      <div class=" img-container">

        <ImageComponent class="icon-link" v-bind:src=url  v-bind:height=height width="auto" alt=""/>

      </div>
    </div>
      <div class="col-lg-12   justify-content-center">
    <p class=" font18 white fw700 text-center p-description" v-html="formattedDescription" v-if="description"  ></p>

  </div>


  </div>
</template>

<script>
import ImageComponent from "@/components/entity/ImageComponent.vue";

export default {
  name: 'InputField',
  components: {ImageComponent},
  props: {
    title: String,
    description: String,
    url: String,
    height: String,
    computed: {},

  },
  data(){
    return {
      menu:require("/src/assets/roshosh/lines.png")
    }
  },
  computed: {
    formattedTitle() {
      // Use a computed property to return the title
      return this.title;
    },formattedDescription() {
      // Use a computed property to return the title
      return this.description;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-content{
  background-color: var(--bs-light);
min-width:163px;
}
.icon-link{
  margin: 5px;
}
.img-container{
  width: max-content;
  max-width:100%;
}
.card-content:hover{
  transition: 0.3s ease-in-out;
  -webkit-box-shadow: 1px -1px 24px 0px rgba(181, 181, 181, 0.75);
  -moz-box-shadow: 1px -1px 24px 0px rgba(181, 181, 181, 0.75);
  transform: scale(1.05);
  cursor: pointer;
  box-shadow: 0 7px 29px 0 var(--main-color);

}
.card-content{
  min-height: max-content;
  margin-top: -30px;
  box-shadow: 1px -1px 24px 0px rgba(181, 181, 181, 0.75);

}
.h-title{
  font-size:18px ;
  font-weight: 700;
}
.p-description{
  font-size: 16px;
}
.card-content{
  justify-content: center;align-items: center;

  text-align: start;
  color:var(--main-color)
;
  transition: all 1s ease-out;
}
.card-content:hover{
}
</style>
